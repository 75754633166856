import React, {lazy, Suspense} from "react"
import {render} from "react-dom"
import {Provider} from "react-redux"
import {Layout} from "@utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import {store} from "@redux/storeConfig/store"
import Spinner from "@components/@vuexy/spinner/Fallback-spinner"
// import "./index.scss"
// 下面的是mock，目前无用
// import "./@fake-db"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
// import "@assets/scss/plugins/extensions/toastr.scss";
// import "prismjs/themes/prism-tomorrow.css"
import './configs/index'

const LazyApp = lazy(() => import("./App"))
// Component.prototype.token=token;
// configureDatabase()

render(
    /* <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}> */
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <Layout>
                {/* <IntlProviderWrapper>*/}
                <div className='todo-application position-relative' style={{border: "none", height: "100%"}}>
                    <LazyApp/>
                </div>
                <ToastContainer/>
                {/* </IntlProviderWrapper>*/}
            </Layout>
        </Suspense>
    </Provider>
    // </Auth0Provider>
    ,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
