import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        clubNo: "",
        userID: "",
        status: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("playerList")
    }
}

const playerList = (state = initialState, action) => {
    switch (action.type) {
        case 'PLAYER_LIST':
            return {
                ...state,
                ...action.payload
            }
        case 'PLAYER_LIST_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("playerList")
                },
            }
        default:
            return {...state};
    }
}

export default playerList
