import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    summary: {},
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        game_id: "",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const pubRoom = (state = initialState, action) => {
    switch (action.type) {
        case 'PUB_ROOM':
            return {
                ...state,
                ...action.payload
            }
        case 'PUB_ROOM_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default pubRoom
