import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    summary: {},
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        app_type: "",
        channel_type: "-1,0,1,2,3,4,5",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const signRecord = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGN_RECORD':
            return {
                ...state,
                ...action.payload
            }
        case 'SIGN_RECORD_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default signRecord
