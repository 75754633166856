import React from "react"
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Input,
    UncontrolledDropdown
} from "reactstrap"
// import PerfectScrollbar from "react-perfect-scrollbar"
// import axios from "axios"
// import * as Icon from "react-feather"
import {User, Power, Search, Menu, X, Home} from "react-feather"
// import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
// import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
// import {useAuth0} from "../../../authServices/auth0/auth0Service"
import {history} from "@history"
// import {IntlContext} from "@utility/context/Internationalization"
// import config from '@/configs'
// import {createInstance} from 'i18next'
import {getLocale, setLocale, decodeUserMess, logoutClearStorage} from '@utility/utils'
import Avatar from "@components/@vuexy/avatar/AvatarComponent";
import "./index.scss"
import baseConfig from "@utility/config";
import {langArrNew} from "@/configs";
import NavbarBell from "@/layouts/components/navbar/NavbarBell";
// import NavbarBell from "@/layouts/components/navbar/NavbarBell";

// const handleNavigation = (e, path) => {
//   e.preventDefault()
//   history.push(path)
// }

const UserDropdown = () => {
    // const {logout, isAuthenticated} = useAuth0()
    return (
        <DropdownMenu right>
            {/* <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/pages/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/email/inbox")}
      >
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/todo/all")}
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/chat")}
      >
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, "/ecommerce/wishlist")}>
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem>
      <DropdownItem divider /> */}
            <DropdownItem
                tag="a"
                href="/setting/MyInfo"
                className="d-flex align-items-center"
                onClick={e => {
                    e.preventDefault()
                    history.push("/setting/MyInfo")
                }}
            >
                <Home size={15} className="mr-50 align-text-top"/>
                <span className="align-middle">{global.t("帐户信息")}</span>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem
                tag="a"
                href="/pages/login"
                className="d-flex align-items-center"
                onClick={e => {
                    e.preventDefault()
                    logoutClearStorage()
                }}
            >
                <Power size={15} className="mr-50 align-text-top"/>
                <span className="align-middle">{global.t("退出登录")}</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

const MenuDropdown = ({keyword, handleBlur}) => {
    // const {logout, isAuthenticated} = useAuth0()
    const rule_data = JSON.parse(localStorage.getItem('rule_data') || "[]");

    return (
        <DropdownMenu>
            {
                rule_data.map(item => <div key={item.id}>
                        <DropdownItem header
                                      key={item.id}
                                      disabled
                                      className={`${item.children.find(e =>
                                          global.t(e.title).toUpperCase().includes(keyword.toUpperCase())) ? "" : "hidden"}`}
                        >
                            <span className="align-middle">{global.t(item.title)}</span>
                        </DropdownItem>
                        {
                            item.children.map(child =>
                                <DropdownItem
                                    key={child.id}
                                    className={`${global.t(child.title).toUpperCase().includes(keyword.toUpperCase()) ? "" : "hidden"}`}
                                    tag="a"
                                    href={child.navLink}
                                    onClick={e => {
                                        e.preventDefault()
                                        history.push(child.navLink)
                                        handleBlur()
                                    }}
                                >
                                    <Menu size={14} className="mr-50"/>
                                    <span className="align-middle">{global.t(child.title)}</span>
                                </DropdownItem>
                            )
                        }
                    </div>
                )
            }
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {
        navbarSearch: false,
        keyword: "",
        langDropdown: false,
        shoppingCart: [
            {
                id: 1,
                name:
                    "Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum",
                desc:
                    "Durable, lightweight aluminum cases in silver, space gray, gold, and rose gold. Sport Band in a variety of colors. All the features of the original Apple Watch, plus a new dual-core processor for faster performance. All models run watchOS 3. Requires an iPhone 5 or later.",
                price: "$299",
                img: require("../../../assets/img/pages/eCommerce/4.png"),
                width: 75
            },
            {
                id: 2,
                name:
                    "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
                desc:
                    "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
                price: "$1599.99",
                img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
                width: 100,
                imgClass: "mt-1 pl-50"
            },
            {
                id: 3,
                name: "Sony - PlayStation 4 Pro Console",
                desc:
                    "PS4 Pro Dynamic 4K Gaming & 4K Entertainment* PS4 Pro gets you closer to your game. Heighten your experiences. Enrich your adventures. Let the super-charged PS4 Pro lead the way.** GREATNESS AWAITS",
                price: "$399.99",
                img: require("../../../assets/img/pages/eCommerce/7.png"),
                width: 88
            },
            {
                id: 4,
                name:
                    "Beats by Dr. Dre - Geek Squad Certified Refurbished Beats Studio Wireless On-Ear Headphones - Red",
                desc:
                    "Rock out to your favorite songs with these Beats by Dr. Dre Beats Studio Wireless GS-MH8K2AM/A headphones that feature a Beats Acoustic Engine and DSP software for enhanced clarity. ANC (Adaptive Noise Cancellation) allows you to focus on your tunes.",
                price: "$379.99",
                img: require("../../../assets/img/pages/eCommerce/10.png"),
                width: 75
            },
            {
                id: 5,
                name:
                    "Sony - 75' Class (74.5' diag) - LED - 2160p - Smart - 3D - 4K Ultra HD TV with High Dynamic Range - Black",
                desc:
                    "This Sony 4K HDR TV boasts 4K technology for vibrant hues. Its X940D series features a bold 75-inch screen and slim design. Wires remain hidden, and the unit is easily wall mounted. This television has a 4K Processor X1 and 4K X-Reality PRO for crisp video. This Sony 4K HDR TV is easy to control via voice commands.",
                price: "$4499.99",
                img: require("../../../assets/img/pages/eCommerce/sony-75class-tv.jpg"),
                width: 100,
                imgClass: "mt-1 pl-50"
            },
            {
                id: 6,
                name:
                    "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
                desc:
                    "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
                price: "$4099.99",
                img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
                width: 70,
                imgClass: "mt-1 pl-50"
            }
        ],
        suggestions: []
    }

    // componentDidMount() {
    //     axios.get("/api/main-search/data").then(({data}) => {
    //         this.setState({suggestions: data.searchResult})
    //     })
    // }

    handleNavbarSearch = () => {
        this.setState({
            keyword: "",
            navbarSearch: !this.state.navbarSearch
        })
    }

    /* removeItem = id => {
      let cart = this.state.shoppingCart;

      let updatedCart = cart.filter(i => i.id !== id)

      this.setState({
        shoppingCart: updatedCart
      })
    }*/

    handleLangDropdown = () =>
        this.setState({langDropdown: !this.state.langDropdown})


    handleBlur = () => {
        document.getElementById("searchMenu777").value = ""
        this.handleNavbarSearch()
    }

    render() {
        const {name, is_pwd = 0} = decodeUserMess()
        const nav_links = JSON.parse(localStorage.getItem('nav_links'))
        const hasCardWarn = nav_links["/club/CardWarn"]
        const hasFeedback = nav_links["/tool/Feedback"]

        return (
            <div className="nav navbar-nav navbar-nav-user float-right">
                <div className={`${this.state.navbarSearch ? "hidden" : ""} d-inline-flex`}>
                    <Dropdown
                        className="dropdown-language nav-item d-flex align-items-center"
                        isOpen={this.state.langDropdown}
                        toggle={this.handleLangDropdown}
                        data-tour="language"
                    >
                        <DropdownToggle
                            tag="a"
                            className="nav-link d-flex align-items-center justify-content-between"
                        >
                            <ReactCountryFlag
                                className="country-flag"
                                // countryCode={
                                //   context.state.locale === "tc"
                                //     ? "cn"
                                //     : context.state.locale
                                // }
                                countryCode={
                                    getLocale() === "tw"
                                        ? "cn"
                                        : getLocale() === 'en' ? 'us' : 'pt'
                                }
                                svg
                            />
                            <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50"
                                  style={{color: "#626262"}}>
                                        {langArrNew[getLocale()].title}
                                    </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {
                                Object.keys(langArrNew).map(item => {
                                    return (
                                        <DropdownItem
                                            tag="a"
                                            key={item}
                                            onClick={() => {
                                                setLocale(item)
                                                // context.switchLanguage(item)
                                            }}
                                        >
                                            <ReactCountryFlag className="country-flag"
                                                              countryCode={langArrNew[item].countryCode} svg/>
                                            <span className="ml-1">{langArrNew[item].title}</span>
                                        </DropdownItem>
                                    )
                                })
                            }
                            {/* <DropdownItem
                      tag="a"
                      onClick={e => context.switchLanguage("tc")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="cn" svg />
                    <span className="ml-1">繁體中文</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="fr" svg />
                    <span className="ml-1">French</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("de")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="de" svg />
                    <span className="ml-1">German</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("pt")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="pt" svg />
                    <span className="ml-1">Portuguese</span>
                  </DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>
                    {/* <IntlContext.Consumer>*/}
                    {/*    {() => {*/}
                    {/*        // let langArr = {*/}
                    {/*        //   "tc" : "繁體中文",*/}
                    {/*        //   "en" : "English",*/}
                    {/*        //   "de" : "German",*/}
                    {/*        //   "fr" : "French",*/}
                    {/*        //   "pt" : "Portuguese"*/}
                    {/*        // };*/}
                    {/*        let langArrNew = {*/}
                    {/*            "en": "English",*/}
                    {/*            "tw": "繁體中文",*/}
                    {/*            "pt": "Portuguese"*/}
                    {/*        };*/}
                    {/*        return (*/}
                    {/*           */}
                    {/*        )*/}
                    {/*    }}*/}
                    {/* </IntlContext.Consumer>*/}
                    <div className="d-flex align-items-center justify-content-center ml-1">
                        {name || ""}
                        <Search size={18}
                                className={`${baseConfig.hostType === 0 ? "" : "hidden"} search`}
                                onClick={() => {
                                    this.handleNavbarSearch()
                                    setTimeout(() => {
                                        document.getElementById("searchMenu777").focus()
                                    }, 0)
                                }}/>
                    </div>
                    {is_pwd === 0 && (hasCardWarn || hasFeedback) && <NavbarBell/>}
                    <UncontrolledDropdown tag="div" className="dropdown-user nav-item">
                        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                            {/* <div className="user-nav d-sm-flex d-none">*/}
                            {/* <span className="text-bold-600">*/}
                            {/* {localStorage.getItem("username")}*/}
                            {/* </span>*/}
                            {/* /!*<span className="user-status">Available</span>*!/*/}
                            {/* </div>*/}
                            <span data-tour="user">
                                <Avatar color='secondary' icon={<User size={14}/>}/>
                            </span>
                        </DropdownToggle>
                        <UserDropdown {...this.props} />
                    </UncontrolledDropdown>
                </div>
                <UncontrolledDropdown tag="div"
                                      isOpen={!!this.state.keyword}
                                      className={`${this.state.navbarSearch ? "" : "hidden"} dropdown-user nav-item`}>
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <span data-tour="user">
                            <div className="form-label-group position-relative has-icon-left searchInput">
                                <Input
                                    id="searchMenu777"
                                    autoComplete="off"
                                    type="text"
                                    placeholder={global.t("菜单")}
                                    // onBlur={() => {
                                    //     document.getElementById("searchMenu777").value = ""
                                    //     this.handleNavbarSearch()
                                    // }}
                                    onInput={e => this.setState({
                                        keyword: e.target.value
                                    })}
                                />
                            <div className="form-control-position">
                                <Search size={20}/>
                            </div>
                              <div className="form-control-position searchCancel">
                                <X size={20} onClick={this.handleBlur}/>
                            </div>
                          </div>
                        </span>
                    </DropdownToggle>
                    <MenuDropdown keyword={this.state.keyword} handleBlur={this.handleBlur}/>
                </UncontrolledDropdown>
            </div>
        )
    }

}

export default NavbarUser
