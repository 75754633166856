const initialState = {
    loading: false,
    submitting: false,
    data: {},
    dataKey: {},
}

const botSetting = (state = initialState, action) => {
    switch (action.type) {
        case 'BOT_SETTING':
            return {
                ...state,
                ...action.payload
            }
        case 'BOT_SETTING_INIT':
            return initialState
        default:
            return {...state};
    }
}

export default botSetting
