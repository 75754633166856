import {getUserConfig} from "@utility/utils";

const initialState = {
    club_id: "",
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    searchParams: {
        userID: "",
        roomID: "",
        page: 1,
        count: 999,
        refresh: false,
        ...getUserConfig("autoClub")
    },
    testRoom: {},
    testRobot: {
        searchParams: {
            userID: "",
            club_id: "",
            page: 1,
            count: 100,
            refresh: false,
            ...getUserConfig("clubRobot")
        },
        list: [],
        total: 0,
        loading: false,
        submitting: false,
    },
}

const autoClub = (state = initialState, action) => {
    switch (action.type) {
        case 'TEST_CLUB_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'TEST_CLUB_ROOM':
            return {
                ...state,
                testRoom: {
                    ...state.testRoom,
                    [action.payload.club_id]: {
                        ...state.testRoom[action.payload.club_id],
                        ...action.payload
                    }
                }
            }
        case 'TEST_CLUB_ROBOT':
            return {
                ...state,
                testRobot: {
                    ...state.testRobot,
                    ...action.payload
                }
            }
        case 'TEST_CLUB_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("cardDeal")
                },
                testRobot: {
                    ...initialState.testRobot,
                    searchParams: {
                        ...initialState.testRobot, ...getUserConfig("clubRobot")
                    },
                }
            }
        case 'TEST_CLUB_ROBOT_INIT':
            return {
                ...state,
                testRobot: {
                    ...initialState.testRobot,
                    searchParams: {
                        ...initialState.testRobot, ...getUserConfig("clubRobot")
                    },
                }
            }
        default:
            return {...state};
    }
}

export default autoClub
