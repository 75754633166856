const initialState = {
    list: [],
    roleEnum: [],
    total: 0,
    roleInfo: [],
    menuList: [],
    loading: false,
    searching: false,
    submitting: false,
    searchParams: {
        keyword: "",
        page: 1,
        count: 30,
        refresh: false,
    }
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ROLE_LIST':
        case 'ROLE_DETAIL':
            return {
                ...state,
                ...action.payload
            }
        case 'ROLE_LIST_INIT':
            return initialState
        default:
            return {...state};
    }
}

export default roleReducer
