import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    summary: {},
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        club_id: "",
        game_id: "-1",
        room_type: "-1,1,2",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const clubUsed = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_USED':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_USED_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default clubUsed
