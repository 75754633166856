// 禁止在dev使用的路由
const devLimitRoute = {
}

// 禁止在uat使用的路由，默认禁用在dev禁止使用的路由
const uatLimitRoute = {
    // "/bot/ClubBotSetting": false,
    // "/bot/BotSetting": false,
    ...devLimitRoute,
}

// 禁止在prd使用的路由, 默认禁用在uat禁止使用的路由
const prdLimitRoute = {
    "/testTool/CardDeal": false,
    ...uatLimitRoute
}

export const routeConfig = [{}, devLimitRoute, uatLimitRoute, prdLimitRoute]
