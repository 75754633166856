import React, {useMemo} from "react"
import {Navbar} from "reactstrap"
import {connect} from "react-redux"
import classnames from "classnames"
import {logoutWithFirebase, logoutWithJWT} from "@redux/actions/auth/loginActions"
import NavbarBookmarks from "./NavbarBookmarks"
import CustomBreadcrumbs from "@components/Breadcrumbs"
import NavbarUser from "./NavbarUser"
// import userImg from "@assets/img/portrait/small/avatar-s-11.jpg"
import {useLocation} from "react-router-dom";
import {decodeUserMess} from "@utility/utils";

// 处理面包屑显示的文本
const NavEnum = {
    // 60: {
    //     pid: 7,
    //     label: "查看详情"
    // },
    // 61: {
    //     pid: 8,
    //     label: "查看详情"
    // },
    // 95: {
    //     pid: 7,
    //     label: "创建实物赛"
    // },
    // 96: {
    //     pid: 7,
    //     label: "创建银币赛"
    // },
}

const UserName = props => {
    let val
    if (props.userdata !== undefined) {
        val = props.userdata.name
    } else if (props.user.login.values !== undefined) {
        val = props.user.login.values.name
        if (
            props.user.login.values.loggedInWith !== undefined &&
            props.user.login.values.loggedInWith === "jwt"
        ) {
            val = props.user.login.values.loggedInUser.username
        }
    } else {
        val = "John Doe"
    }

    return val
}
const ThemeNavbar = props => {
    // const {user} = useAuth0()
    const {user} = {}
    const location = useLocation()
    const {is_first_login = 0, is_pwd = 0, is_google_auth = 0, is_need_validate_google2fa = 0} = decodeUserMess()

    const rule_data = useMemo(() => {
        let obj = {}
        try {
            const handleNav = (parentItem, nav) => {
                nav.forEach(item => {
                    if (item.navLink && item.navLink !== "#") {
                        const curNav = [
                            {
                                key: item.navLink,
                                name: NavEnum[item.id]?.label || item.title,
                                path: item.navLink
                            }
                        ]
                        if (item.is_menu === 0) {
                            obj[item.navLink] = parentItem.id ?
                                [{
                                    id: parentItem.pid,
                                    key: parentItem.navLink,
                                    name: NavEnum[parentItem.id]?.label || parentItem.title,
                                    path: parentItem.navLink
                                }].concat(curNav) : curNav
                            if (obj[parentItem.pid]) obj[item.navLink] = obj[parentItem.pid].concat(obj[item.navLink])
                        } else {
                            obj[item.navLink] = curNav
                            if (obj[parentItem.id]) obj[item.navLink] = obj[parentItem.id].concat(obj[item.navLink])
                        }
                    } else {
                        obj[item.id] = [
                            {
                                id: item.id,
                                key: item.navLink,
                                name: NavEnum[item.id]?.label || item.title,
                                path: item.navLink
                            }
                        ]
                    }
                    if (item.children && item.children.length > 0) {
                        handleNav(item, item.children)
                    }
                })
            }
            handleNav({}, JSON.parse(localStorage.getItem("rule_data")))
            // console.log(obj)
            return obj
        } catch (e) {
            // console.log(e)
            return {}
        }
    }, [localStorage.getItem("rule_data")])

    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating", "static", "sticky", "hidden"]
    return (
        <React.Fragment>
            <div className="content-overlay"/>
            <div className=""/>
            <Navbar
                className={classnames(
                    "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
                    {
                        "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
                        "navbar-dark": colorsArr.includes(props.navbarColor),
                        "bg-primary":
                            props.navbarColor === "primary" && props.navbarType !== "static",
                        "bg-danger":
                            props.navbarColor === "danger" && props.navbarType !== "static",
                        "bg-success":
                            props.navbarColor === "success" && props.navbarType !== "static",
                        "bg-info":
                            props.navbarColor === "info" && props.navbarType !== "static",
                        "bg-warning":
                            props.navbarColor === "warning" && props.navbarType !== "static",
                        "bg-dark":
                            props.navbarColor === "dark" && props.navbarType !== "static",
                        "d-none": props.navbarType === "hidden" && !props.horizontal,
                        "floating-nav":
                            (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
                        "navbar-static-top":
                            props.navbarType === "static" && !props.horizontal,
                        "fixed-top": props.navbarType === "sticky" || props.horizontal,
                        "scrolling": props.horizontal && props.scrolling

                    }
                )}
            >
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div
                            className="navbar-collapse d-flex justify-content-between align-items-center"
                            id="navbar-mobile"
                        >
                            <div className="bookmark-wrapper d-flex align-items-center">
                                <NavbarBookmarks
                                    sidebarVisibility={props.sidebarVisibility}
                                    handleAppOverlay={props.handleAppOverlay}
                                />
                                <CustomBreadcrumbs headerData={
                                    (is_first_login === 1 || is_need_validate_google2fa === 1) && (is_pwd === 1 || is_google_auth === 1) ?
                                        [{
                                            key: "newUser",
                                            name: is_pwd === 1 ? global.t("修改密码") : global.t("绑定二维码"),
                                            path: "",
                                        }] : rule_data[location.pathname]
                                }/>
                            </div>
                            {props.horizontal ? (
                                <div className="logo d-flex align-items-center">
                                    <div className="brand-logo mr-50"/>
                                    <h2 className="text-primary brand-text mb-0">EZWIN</h2>
                                </div>
                            ) : null}
                            <NavbarUser
                                handleAppOverlay={props.handleAppOverlay}
                                changeCurrentLang={props.changeCurrentLang}
                                userName={<UserName userdata={user} {...props} />}
                                /* userImg={
                                    props.user.login.values !== undefined &&
                                    props.user.login.values.loggedInWith !== "jwt" &&
                                    props.user.login.values.photoUrl
                                        ? props.user.login.values.photoUrl
                                        : user !== undefined && user.picture ? user.picture
                                        : userImg
                                } */
                                loggedInWith={
                                    props.user &&
                                    props.user.login.values !== undefined
                                        ? props.user.login.values.loggedInWith
                                        : null
                                }
                                logoutWithJWT={props.logoutWithJWT}
                                logoutWithFirebase={props.logoutWithFirebase}
                            />
                        </div>
                    </div>
                </div>
            </Navbar>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.auth,
        headerData: state.other.headerData
    }
}

export default connect(mapStateToProps, {
    logoutWithJWT,
    logoutWithFirebase,
})(ThemeNavbar)
