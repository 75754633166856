import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    searchParams: {
        refresh: false,
        adminID: "",
        search: "",
        page: 1,
        count: 10,
    },
    details: {
        list: [],
        detailList: [],
        data: {},
        total: 0,
        loading: false,
        searching: false,
        searchParams: {
            refresh: false,
            adminID: "",
            search: "",
            page: 1,
            count: 30,
            ...getUserConfig("systemEmail")
        },
    }
}

const mailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SYS_EMAIL_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'SYS_EMAIL_RELOAD':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    refresh: true
                }
            }
        case 'SYS_EMAIL_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams,
                    ...getUserConfig("systemEmail")
                }
            }
        case 'SYS_EMAIL_DETAIL':
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload,
                }
            }
        default:
            return {...state};
    }
}

export default mailReducer
