import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    submitting: false,
    searchParams: {
        userID: "",
        operatorID: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("whiteManage")
    }
}

const whiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WHITE_MANAGE':
            return {
                ...state,
                ...action.payload
            }
        case 'WHITE_MANAGE_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("whiteManage")
                }
            }
        default:
            return {...state};
    }
}

export default whiteReducer
