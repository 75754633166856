/**
 * Created by S.WC on 2020/11/18.
 */

import {routeConfig} from "./routeConfig";
const configExtra = require('./configExtra');

const config = {
    baseImageUrl: "https://ujmjimg.jengamahjong.com/imgpath/",
    localHost: [
        "https://local.com/api",
        "https://dev.com/api",
        "https://ujmjcapi.jengamahjong.com/api",
        "https://prod.com/api"
    ],
    routeConfig,
};

const BaseConfig = Object.assign({}, config, configExtra)

export default BaseConfig

