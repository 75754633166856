import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        player_user_id: "",
        start_date: new Date(),
        end_date: new Date(),
        sort_by: 2,
        sort_type: 2,
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("playerRanking")
    }
}

const ranking = (state = initialState, action) => {
    switch (action.type) {
        case 'PLAYER_RANKING':
            return {
                ...state,
                ...action.payload
            }
        case 'PLAYER_RANKING_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("playerRanking")
                },
            }
        default:
            return {...state};
    }
}

export default ranking
