import {getUserConfig, getWeekDay} from "@utility/utils";

const data =getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    testing: false,
    searchParams: {
        refresh: false,
        keyword: "",
        globalText: "",
        blocked_type: "-1,1,2,3,4,5,6,7,8,9",
        operatorID: "",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        ...getUserConfig("mask")
    }
}

const mask = (state = initialState, action) => {
    switch (action.type) {
        case 'MASK_MANAGE':
            return {
                ...state,
                ...action.payload
            }
        case 'MASK_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams,
                    ...getUserConfig("mask")
                }
            }
        default:
            return {...state};
    }
}

export default mask
