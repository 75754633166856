import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    searchParams: {
        cType: 0,
        currency_type: "",
        orderNo: "",
        user_info: "",
        operatorName: "",
        start_date: new Date(),
        end_date: new Date(),
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("hallCredit")
    }
}

const hallCredit = (state = initialState, action) => {
    switch (action.type) {
        case 'HALL_CREDIT':
            return {
                ...state,
                ...action.payload
            }
        case 'HALL_CREDIT_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("hallCredit")
                },
            }
        default:
            return {...state};
    }
}

export default hallCredit
