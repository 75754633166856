const initialState = {
    orl: {
        mode: "",
        details: "",
    },
    st: {
        mode: "",
        details: "",
    },
    loading: false,
    submitting: false,
}

const regionalSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REGIONAL_SET':
            return {
                ...state,
                ...action.payload
            }
        case 'REGIONAL_SET_INIT':
            return initialState
        default:
            return {...state};
    }
}

export default regionalSetReducer
