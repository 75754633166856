import {getUserConfig, getWeekDay} from "@utility/utils";

const data = getWeekDay(0)

const initialState = {
    combined: {},
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        club_id: "",
        playerID: "",
        userID: "",
        game_ids: "-1",
        start_date: data[0],
        end_date: data[1],
        connect_start_date: data[0],
        connect_end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubReport")
    },
    agentLink: []
}

const clubReport = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_REPORT':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_REPORT_ROUTE':
            return {
                ...state,
                ...action.payload,
                searchParams: {
                    ...state.searchParams,
                    ...action.payload.searchParams
                }
            }
        case 'CLUB_REPORT_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubReport")
                },
            }
        default:
            return {...state};
    }
}

export default clubReport
