import {combineReducers} from "redux"
import email from './email'
import sysEmail from './sysEmailReducer'
import loop from './loop'
import loop2 from './loop2'
import urgent from './urgent'
import push from './push'
import activity from './activity'
import maintain from './maintain'
import update from './update'

const noticeReducer = combineReducers({
    email,
    sysEmail,
    loop,
    loop2,
    urgent,
    push,
    activity,
    maintain,
    update,
})

export default noticeReducer
