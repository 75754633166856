const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        page: 1,
        game_id: "-1",
        refresh: false,
    }
}

const gameManageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GAME_MANAGE':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

export default gameManageReducer
