import {getUserConfig, getWeekDay} from "@utility/utils";

const data =getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    searching: false,
    loading: false,
    detail: {},
    searchParams: {
        clubNo: "",
        userID: "",
        roomID: "",
        event_type: "-1,3010000,3020000,2010200,2020200,2030200,3040300,3040400,3040000,3050000,9040000,combine1,combine2,5010100,5010200,match1,5050000",
        gameIDs: "-1",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubPointFlow")
    }
}

const clubPointFlow = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_POINT_FLOW':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_POINT_FLOW_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubPointFlow")
                },
            }
        case 'CLUB_POINT_FLOW_DETAIL':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_POINT_FLOW_DETAIL_INIT':
            return {
                ...state,
                searching: false,
                detail: {},
            }
        default:
            return {...state};
    }
}

export default clubPointFlow
