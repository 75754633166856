import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    searchParams: {
        refresh: false,
        status_tag: 0,
        adminID: "",
        start_date: "",
        end_date: "",
        page: 1,
        count: 30,
        ...getUserConfig("push")
    }
}

const pushReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PUSH_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'PUSH_DATA_RELOAD':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    refresh: true
                }
            }
        case 'PUSH_DATA_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams,
                    ...getUserConfig("push")
                }
            }
        default:
            return {...state};
    }
}

export default pushReducer
