import axios from "@utility/axios";

const cardWarnNum = "/club/roomCardWarnNum"

export const loadSuggestions = () => {
    return () => {
        // axios.get("/search/bookmarks/data").then(response => {
        //     dispatch({
        //         type: "MAYBE_UPDATE_SUGGESTIONS",
        //         suggestions: response.data.searchResult
        //     })
        // })
    }
}

export const updateStarred = () => {
    return () => {
        // axios
        //     .post("/update/bookmarks", {
        //         obj: object
        //     })
        //     .then(() => {
        //         dispatch({
        //             type: "UPDATE_STARRED",
        //             object
        //         })
        //         dispatch(loadSuggestions())
        //     })
    }
}

export const getUnReviewNoticeNum = () => {
    return () => axios.get("/admin/get-notice")
        .then(res => {
            const num = res?.reduce((pre, next) => pre + next.num, 0)
            return {
                data: res,
                num,
            }
        })
}

// 获取通知
export const getCardWarnNum = params => {
    const nav_links = JSON.parse(localStorage.getItem('nav_links'))
    const hasCardWarn = nav_links["/club/CardWarn"]
    const hasFeedback = nav_links["/tool/Feedback"]

    if (hasCardWarn || hasFeedback) {
        return dispatch => {
            dispatch({type: 'NAV_CARD_WARN', payload: {loading: true}})
            const lastNoticeTime = localStorage.getItem("lastNoticeTime")

            if (!params?.update || (lastNoticeTime && (Date.parse(new Date()) > lastNoticeTime))) {
                axios.get(cardWarnNum, {params})
                    .then(res => {
                        const {warn, unread, critical} = res
                        if (hasCardWarn) dispatch({
                            type: 'NAV_CARD_WARN', payload: {
                                loading: false,
                                value: warn
                            }
                        })
                        if (hasFeedback) dispatch({
                            type: 'NAV_FEEDBACK', payload: {
                                unread, critical
                            }
                        })

                        localStorage.setItem('lastNoticeTime', Date.parse(new Date(new Date().getTime() + 300000)))
                    })
                    .catch(e => {
                        if (e) dispatch({type: 'NAV_CARD_WARN', payload: {loading: false}})
                    })

            }
        }
    }

    return true
}
