import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        app_type: "",
        channel_type: "-1,0,1,2,3,4,5",
        rechargeType: "-1,0,2,3",
        start_date: data[0],
        end_date: data[1],
        showTest: true,
        refresh: false,
    }
}

const payRecord = (state = initialState, action) => {
    switch (action.type) {
        case 'PAY_RECORD':
            return {
                ...state,
                ...action.payload
            }
        case 'PAY_RECORD_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default payRecord
