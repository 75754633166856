import {combineReducers} from "redux"
import clubList from "./clubManage"
import roomCard from "./roomCard"
import editRecord from "./editRecord"
import member from "./member"
import clubPointFlow from "./pointFlow";
import ranking from "./ranking";
import changeRecord from "./changeRecord";
import clubReport from "./report";
import pointCredit from "./pointCredit";
import cardWarn from "./cardWarn";
import deskRate from "./deskRate";
import uniquePlay from "./uniquePlay";
import memberRecord from "./memberRecord";

const clubReducer = combineReducers({
    clubList,
    roomCard,
    editRecord,
    member,
    clubPointFlow,
    ranking,
    changeRecord,
    clubReport,
    pointCredit,
    cardWarn,
    deskRate,
    uniquePlay,
    memberRecord,
})

export default clubReducer
