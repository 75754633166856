import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    searchParams: {
        player_user_id: "",
        event_types: "-1,1010000,1020000,1030000,2040102,2040300,2040400,2040500,9030000,9050000",
        related_gameIDs: "-1",
        start_date: new Date(),
        end_date: new Date(),
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("playerRoomCard")
    }
}

const roomCard = (state = initialState, action) => {
    switch (action.type) {
        case 'PLAYER_ROOM_CARD':
            return {
                ...state,
                ...action.payload
            }
        case 'PLAYER_ROOM_CARD_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("playerCoinRecord")
                },
            }
        default:
            return {...state};
    }
}

export default roomCard
