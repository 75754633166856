import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    loading: false,
    searchParams: {
        page: 1,
        count: 30,
        id: "",
        refresh: false,
        ...getUserConfig("goodNum")
    }
}

const goodNum = (state = initialState, action) => {
    switch (action.type) {
        case 'GOOD_NUM':
            return {
                ...state,
                ...action.payload
            }
        case 'GOOD_NUM_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("goodNum")
                }
            }
        default:
            return {...state};
    }
}

export default goodNum
