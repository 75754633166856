import {combineReducers} from "redux";
import payRecord from "./payRecord"
import signRecord from "./signRecord"
import retain from "./retain"
import clubUsed from "./clubUsed"
import framework from "./framework"
import activePlayer from "./activePlayer"
import clubActive from "./clubActive"
import pubRoom from "./pubRoom"
import privRoom from "./privRoom";
import mjData from "./mjData";

const initialState = {
    col: "",
    sort: "",
    count: 100
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OP_COMMON':
            return {
                ...state,
                ...action.payload
            }
        case 'OP_COMMON_INIT':
            return initialState
        default:
            return {...state};
    }
}

const operationReducer = combineReducers({
    payRecord,
    signRecord,
    retain,
    clubUsed,
    framework,
    activePlayer,
    clubActive,
    pubRoom,
    privRoom,
    mjData,
    commonReducer
})

export default operationReducer
