import {combineReducers} from "redux"
import game from "./gameManageReducer"
import opLog from "./opLogReducer"
import role from "./roleReducer"
import user from "./userReducer"
import white from "./whiteReducer"
import whiteIP from "./whiteIP"
import loginLog from "./loginLog"
import goodNum from "./goodNum"
import regionalSet from "./regionalSet"
import mask from "./mask"
import mts from "./mts"

const settingReducer = combineReducers({
    game,
    user,
    role,
    opLog,
    white,
    whiteIP,
    loginLog,
    goodNum,
    regionalSet,
    mask,
    mts,
})

export default settingReducer
