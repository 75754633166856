import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    searchParams: {
        clubNo: "",
        userID: "",
        roomNo: "",
        roomID: "",
        settleID: "",
        matchNo: "",
        roomType: "",
        status: 1,
        is_senior: "",
        gameIDs: "-1",
        start_date: undefined,
        end_date: undefined,
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("roomList")
    },
}

const roomList = (state = initialState, action) => {
    switch (action.type) {
        case 'ROOM_LIST':
            return {
                ...state,
                ...action.payload
            }
        case 'ROOM_LIST_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("roomList")
                },
            }
        default:
            return {...state};
    }
}

export default roomList
