import {combineReducers} from "redux"

const initBasicState = {
    // 初始化表格組件的頁碼
    resetTablePage: false,
}

const initStatusState = {
    loading: false,
    submitting: false,
    searching: false,
    pending: false,
}

const initialState = {
    searching: false,
    opList: [{label: "全部", value: ""}],
    opObj: {},
}

const initialOtherListState = {
    club: {
        searching: false,
        clubList: [],
        clubObj: {},
    },
    game: {
        searching: false,
        gameList: [],
        gameObj: {},
    },
    bundle: {
        searching: false,
        bundle: [],
        bundleObj: {},
    },
    player: {
        searching: false,
        pending: false,
        playerList: [],
    }
}

const op = (state = initialState, action) => {
    switch (action.type) {
        case 'OP_USER_DATA':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

const otherList = (state = initialOtherListState, action) => {
    switch (action.type) {
        case 'OTHER_LIST_CLUB':
            return {
                ...state,
                club: {
                    ...state.club,
                    ...action.payload,
                }
            }
        case 'OTHER_LIST_PLAYER':
            return {
                ...state,
                player: {
                    ...state.player,
                    ...action.payload,
                }
            }
        case 'OTHER_LIST_GAME':
            return {
                ...state,
                game: {
                    ...state.game,
                    ...action.payload,
                }
            }
        case 'OTHER_LIST_BUNDLE':
            return {
                ...state,
                bundle: {
                    ...state.bundle,
                    ...action.payload,
                }
            }
        default:
            return {...state};
    }
}

const basic = (state = initBasicState, action) => {
    switch (action.type) {
        case 'HANDLE_BASIC':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

const status = (state = initStatusState, action) => {
    switch (action.type) {
        case 'CUSTOM_STATUS':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

const otherReducer = combineReducers({
    op, otherList, basic, status
})

export default otherReducer
