import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        userID: "",
        roomID: "",
        gameID: "-1",
        operatorID: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("cardDeal")
    },
}

const cardDeal = (state = initialState, action) => {
    switch (action.type) {
        case 'CARD_DEAL':
            return {
                ...state,
                ...action.payload
            }
        case 'CARD_DEAL_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("cardDeal")
                },
            }
        default:
            return {...state};
    }
}

export default cardDeal
