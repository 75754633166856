import {combineReducers} from "redux"
import playerList from "./playerList"
import loginLog from "./loginLog"
import sign from "./sign"
import ranking from "./ranking"
import coinRecord from "./coinRecord"
import roomCard from "./roomCard"

const playerReducer = combineReducers({
    playerList,
    loginLog,
    sign,
    ranking,
    coinRecord,
    roomCard,
})

export default playerReducer
