import {combineReducers} from "redux"
import roomList from "./room"
import roomClub from "./club"
import roomPub from "./pub"
import roomPriv from "./priv"
import roomGameRecord from "./roomGameRecord"
import hallTemplate from "./hallTemplate"

const roomReducer = combineReducers({
    roomList,
    roomClub,
    roomPub,
    roomPriv,
    roomGameRecord,
    hallTemplate,
})

export default roomReducer
