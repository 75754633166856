import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        page: 1,
        count: 30,
        clubNo: "",
        owner_info: "",
        create_info: "",
        platform: "",
        tagID: "",
        status: "0,1",
        refresh: false,
        ...getUserConfig("clubManage")
    },
    clubAllNo: {
        pending: false,
        nextID: "",
        NOList: [],
        beaNo: [],
    }
}

const clubManage = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_MANAGE':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_MANAGE_RELOAD':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    refresh: true
                }
            }
        case 'CLUB_MANAGE_NO':
            return {
                ...state,
                clubAllNo: {
                    ...state.clubAllNo,
                    ...action.payload
                }
            }
        case 'CLUB_SEARCH_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubManage")
                },
            }
        default:
            return {...state};
    }
}

export default clubManage
