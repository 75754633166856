import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    searchParams: {
        userID: "",
        start_date: new Date(),
        end_date: new Date(),
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("playerLoginLog")
    }
}

const loginLog = (state = initialState, action) => {
    switch (action.type) {
        case 'PLAYER_LOGIN_LOG':
            return {
                ...state,
                ...action.payload
            }
        case 'PLAYER_LOGIN_LOG_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("playerLoginLog")
                },
            }
        default:
            return {...state};
    }
}

export default loginLog
