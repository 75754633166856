import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    summary: {},
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        club_id: "",
        mj_type: "-1,1,2",
        room_type: "0",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const mjData = (state = initialState, action) => {
    switch (action.type) {
        case 'MJ_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'MJ_DATA_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default mjData
