import {combineReducers} from "redux"
import customizer from "./customizer"
import auth from "./auth"
import navbar from "./navbar"
import user from "./user"
import notice from "./notice"
import setting from "./setting"
import club from "./club"
import room from "./room"
import recharge from "./recharge"
import tool from "./tool"
import testTool from "./testTool"
import player from "./player"
import operation from "./operation"
import otherReducer from "./other"
import bot from "./bot"

const rootReducer = combineReducers({
    customizer,
    auth,
    navbar,
    user,
    notice,
    setting,
    club,
    room,
    recharge,
    tool,
    testTool,
    player,
    operation,
    bot,
    other: otherReducer,
})

export default rootReducer
