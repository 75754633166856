import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searching: false,
    submitting: false,
    searchParams: {
        refresh: false,
        status_tag: "-1,1,2,3",
        isType: "-1,1,2,3,4,5,6",
        playerID: "",
        content: "",
        operatorID: "",
        page: 1,
        count: 30,
        start_date: "",
        end_date: "",
        ...getUserConfig("feedback")
    }
}

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FEEDBACK_LIST':
        case 'FEEDBACK_DETAIL':
        case 'FEEDBACK_EMAIL':
            return {
                ...state,
                ...action.payload
            }
        case 'FEEDBACK_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams,
                    ...getUserConfig("feedback")
                }
            }
        default:
            return {...state};
    }
}

export default feedbackReducer
