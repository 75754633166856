import axios from 'axios' // axios引用
// import store from '../../store' // store引用
import {toast} from "react-toastify"
import {decode, getErrorStr, logoutClearStorage} from '@utility/utils'
import baseConfig from './config'
import {decodeSettle} from "@views/room/Components/utils";
import {errorStr} from "@utility/enum";

const serivce = axios.create({ // 创建服务
    baseURL: baseConfig.localHost[baseConfig.hostType], // 基础路径
    timeout: 20000 // 请求延时
})

// 取消请求操作
const allPendingRequestsRecord = [];
const pending = {};

const removeAllPendingRequestsRecord = () => {
    allPendingRequestsRecord && allPendingRequestsRecord.forEach((func) => {
        // 取消请求（调用函数就是取消该请求）
        func('allCancel');
    });
    // 移除所有记录
    allPendingRequestsRecord.splice(0);
}


// 取消同一个重复的ajax请求
const removePending = (key, isRequest = false) => {
    if (pending[key] && isRequest) {
        pending[key]('cancel');
    }
    delete pending[key];
}

// 取消所有请求的函数
export const getConfirmation = (mes = '', callback = () => {
}) => {
    removeAllPendingRequestsRecord();
    callback();
}

serivce.interceptors.request.use( // 请求拦截
    config => {
//   if (store.getters.userToken) {
//    config.headers['X-Token'] = store.getters.userToken
//    config.headers['User-Type'] = store.getters.userType ? store.getters.userType : '' // 请求头中存放玩家信息
//    config.onUploadProgress = (progressEvent) => {
//     var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
//     store.dispatch('setupLoadPercent', complete)
//    }
//   }
        const token = localStorage.getItem('token')
        // if (token) config.headers['Authorization'] = localStorage.getItem('token_type') + ' ' + token
        if (token) config.headers['Authorization'] = "Bearer" + token
        config.headers['lang'] = localStorage.getItem('local')

        let reqData = '';
        // config.transformResponse = [function (data) {
        //     return data
        // }]
        // 处理如url相同请求参数不同时上一个请求被屏蔽的情况
        if (config.method === 'get') {
            // 组件内用的参数refresh，不需要传递,默认去除
            reqData = `${config.url}${config.method}${config.params?.isUnique === false ? JSON.stringify(config.params) : ""}`
            config.params = {
                ...config.params,
                roomID: config.params?.roomID ? window.location.pathname.includes("testTool") ? config.params?.roomID :
                    decode(config.params?.roomID) ? decode(config.params?.roomID) : "-1" : undefined,
                settleID: config.params?.settleID ? decodeSettle(config.params?.settleID) : undefined,
                refresh: undefined,
                isUnique: undefined,
            }

            // + JSON.stringify(config.params);
        } else {
            reqData = config.url + config.method + JSON.stringify(config.data);
        }
        // 如果玩家连续点击某个按钮会发起多个相同的请求，可以在这里进行拦截请求并取消上一个重复的请求
        removePending(reqData, true);
        // 设置请求的 cancelToken（设置后就能中途控制取消了）
        config.cancelToken = new axios.CancelToken((c) => {
            pending[reqData] = c;
            allPendingRequestsRecord.push(c);
        });

        return config
    },
    error => {
        return Promise.reject(error)
    }
)
serivce.interceptors.response.use( // 回复拦截，主要针对部分回掉数据状态码进行处理
    response => {
        const {data, status} = response
        // console.log(response.data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2'))
        // console.log(response.data.replace(/:s*([0-9]{15,}[.][0-9]?)s*(,?)/g, ': "$1" $2').replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1"$2'))
        // response.data.replace(/:s*(([1-9]\d*\.?\d*)|(0\.\d*[1-9]))s*(,?)/g, ': "$1" $2')
        // const data = JSON.parse(response.data.replace(/:s*([0-9]{15,}.?[0-9]?)s*(,?)/g, ': "$1" $2'))
        // let data
        // try {
        //     data = JSON.parse(response.data.replace(/:s*([-]?[0-9]{15,}[.][0-9]+)s*(,?)/g, ': "$1" $2').replace(/:s*([-]?[0-9]{15,})s*(,?)/g, ': "$1"$2'))
        // } catch {
        //     data = JSON.parse(response.data)
        // }
        if (status === 200) {
            const {code} = data
            if (code === 401 || code === 7002) {
                window.location.href = '/pages/login'
                return Promise.reject('error')
            } else if (code === 4001) {
                toast.error(`${getErrorStr(code)}${data.data ? `：${errorStr[data.data] ? `【${errorStr[data.data]}】` : ""}${data.data}` : ""}`, {
                    toastId: code,
                    position: toast.POSITION.TOP_CENTER,
                })
                return Promise.reject('error')
            } else if ([4002, 4016].includes(code)) {
                toast.warn(getErrorStr(code), {
                    toastId: code,
                    position: toast.POSITION.TOP_CENTER,
                })
                setTimeout(() => {
                    logoutClearStorage()
                }, 1000)
                return Promise.reject('error')
            } else if (code === 402) {
                toast.error(getErrorStr(code), {
                    toastId: "402",
                    position: toast.POSITION.TOP_CENTER
                });
                return Promise.reject('error')
            } else if (code === 4011 || code === 4067) {
                return data.data
            } else if (code === 200 || code === 201 || code === 1 || code === 2 || code === 3 || code === 4023 || code === 7004) {
                // let res = data.data
                // if(Object.prototype.toString.call(data.data) === '[object Object]'){
                //     res.code = code
                // } else{
                //     res = {data: data.data, code}
                // }
                return data.data
            } else {
                toast.error(getErrorStr(code), {
                    toastId: code,
                    position: toast.POSITION.TOP_CENTER
                });
                return Promise.reject('error')
            }
        } else {
            toast.error(status, {
                toastId: "Status Error",
                position: toast.POSITION.TOP_CENTER
            });
            return Promise.reject('error')
        }
    },
    error => {
        if (error.code === "ECONNABORTED" || error.message.includes("timeout")) {
            toast.error(global.t("Timeout Error"), {
                toastId: "Timeout",
                position: toast.POSITION.TOP_CENTER
            })
            console.error(error?.config?.url)
            return Promise.reject(error)
        } else if (!axios.isCancel(error)) {
            console.error([{all: error, response: error?.response}])
            try {
                const status = error?.response?.status || "Network Error"
                const message = error?.response?.data?.message || "Network Error"
                toast.error(global.t(message), {
                    toastId: status,
                    position: toast.POSITION.TOP_CENTER
                });
            } catch {
                toast.error(global.t("Network Error"), {
                    toastId: "Network Error",
                    position: toast.POSITION.TOP_CENTER
                });
            }
            return Promise.reject(error)
        } else {
            return Promise.reject(!axios.isCancel(error))
        }
    }
)

export default serivce
