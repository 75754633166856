import React from 'react'
import PropTypes from 'prop-types'
import {UncontrolledTooltip} from "reactstrap"

// 数据内容太多的时候使用
const TipText = (props) => {
    const {
        content,
        selector,
        index,
        position = 'top',
        style = {},
        surfaceText,
        surfaceContent,
        className,
        tipShow = true,
        onlyText = false
    } = props

    const str = content && typeof content === 'object' ? Object.values(content).join(',') : content
    const handleText = (val) => {
        if (onlyText) return val
        try {
            return typeof val === "string" ? global.t(val) : val
        } catch {
            return val
        }
    }

    return <>
        <span id={`position${position}_${selector}_${index}`} className={`ellipsis ${className}`}
              style={{display: 'block', cursor: 'poniter', maxWidth: "300px", lineHeight: "15px", ...style}}>
            {handleText(surfaceText || str)}
        </span>
        {tipShow && <UncontrolledTooltip key={`position${position}_${selector}_${index}`}
                                         placement={position}
                                         autohide={false}
                                         target={`position${position}_${selector}_${index}`}>
            {surfaceContent || handleText(str)}
        </UncontrolledTooltip>}
    </>
}

TipText.propTypes = {
    content: PropTypes.any,
    surfaceText: PropTypes.any,
    selector: PropTypes.string,
    index: PropTypes.any,
    position: PropTypes.string,
    className: PropTypes.string,
    onlyText: PropTypes.bool,
    tipShow: PropTypes.bool,
    style: PropTypes.object, // maxWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number])
}

export default TipText
