import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    expandingId: "",
    pending: false,
    submitting: false,
    searchParams: {
        clubNo: "",
        club_name: "",
        avatar_url: "",
        userID: "",
        viewMode: "",
        gameStatus: "",
        position: "",
        is_auth: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubMember")
    }
}

const member = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_MEMBER':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_MEMBER_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubMember")
                },
            }
        default:
            return {...state};
    }
}

export default member
