import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    pending: false,
    submitting: false,
    refresh: false,
    google2FAQRCode: {},
    usedClubs: {},
    ownerClubs: {},
    searchParams: {
        refresh: false,
        keyword: "",
        status: "",
        role_id: "",
        pid: "",
        page: 1,
        count: 30,
        ...getUserConfig("userManage")
    },
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_MANAGE':
            return {
                ...state,
                ...action.payload
            }
        case 'USER_SEARCH_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("userManage")
                },
            }
        default:
            return {...state};
    }
}

export default userReducer
