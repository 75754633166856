import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    hallConfig: [],
    total: 0,
    loading: false,
    validing: false,
    submitting: false,
    pending: false,
    searchParams: {
        status: "",
        gameLevel: "",
        gameIDs: "-1",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("hallTemplate")
    },
}

const hallTemplate = (state = initialState, action) => {
    switch (action.type) {
        case 'HALL_TEMPLATE':
            return {
                ...state,
                ...action.payload
            }
        case 'HALL_TEMPLATE_CONFIG':
            return {
                ...state,
                hallConfig: {
                    ...state.hallConfig,
                    ...action.payload
                }
            }
        case 'HALL_TEMPLATE_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("hallTemplate")
                },
            }
        default:
            return {...state};
    }
}

export default hallTemplate
