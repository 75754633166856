import React from "react"
import {
    Database,
    Circle,
    List,
    StopCircle,
    Tool,
    Settings,
    Users,
    LifeBuoy,
    Server,
    Mail,
    CreditCard, Cpu
} from "react-feather"
import baseConfig from "@utility/config";

/**
 * 处理菜单数据
 * @param level 层级
 * @param ruleData 菜单数据
 * @returns {*}
 */
export function handleRuleData(level, ruleData) {
    return ruleData.reduce((pre, item) => {
        // 不存在限制列表中时
        if (baseConfig.routeConfig[baseConfig.hostType][item.navLink] !== false) {
            if (item.navLink === '#') delete item.navLink
            // item.id = item.title.toLowerCase().replace(' ', '');
            if (item.children && item.children.length > 0 && level === 0) {
                item.children = handleRuleData(level + 1, item.children);
            } else {
                delete item.children
            }
            item.icon = generateIcon(item.icon)
            pre.push(item)
        }
        return pre
    }, [])
    /* return demoNavgationConfig.concat(ruleData.map((item) => {
        if (item.navLink == '#') delete item.navLink
        // item.id = item.title.toLowerCase().replace(' ', '');
        if (item.children && item.children.length > 0) {
            handleRuleData(item.children);
        } else {
            delete item.children
        }
        item.icon = generateIcon(item.icon)
        return item
    }))*/
}

function generateIcon(iconStr) {
    let icon = null;
    switch (iconStr) {
        case 'Operations':
            icon = <Database size={20}/>
            break;
        case 'Users':
            icon = <Users size={20}/>
            break;
        case 'Recharge':
            icon = <CreditCard size={20}/>
            break;
        case 'Circle':
            icon = <Circle size={12}/>
            break;
        case 'List':
            icon = <List size={20}/>
            break;
        case 'StopCircle':
            icon = <StopCircle size={20}/>
            break;
        case 'Notice':
            icon = <Mail size={20}/>
            break;
        case 'Club':
            icon = <LifeBuoy size={20}/>
            break;
        case 'Room':
            icon = <Server size={20}/>
            break;
        case 'Tool':
            icon = <Tool size={20}/>
            break;
        case 'Setting':
            icon = <Settings size={20}/>
            break;
        case 'Bot':
            icon = <Cpu size={20}/>
            break;
        default:
            break;
    }
    return icon;
}

// export const demoNavgationConfig = [{
//     id: 99,
//     pid: 0,
//     title: "Demo",
//     sort_number: 200000,
//     type: "collapse",
//     is_menu: 1,
//     level: 0,
//     navLink: "#",
//     icon: <Icon.Briefcase size={20}/>,
//     children: [
//         {
//             id: 991,
//             pid: 99,
//             title: "Buttons",
//             navLink: "/demo/Buttons",
//             sort_number: 999991,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         },
//         {
//             id: 992,
//             pid: 99,
//             title: "Models",
//             navLink: "/demo/Models",
//             sort_number: 999992,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         },
//         {
//             id: 993,
//             pid: 99,
//             title: "Forms",
//             navLink: "/demo/Forms",
//             sort_number: 999993,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         },
//         {
//             id: 994,
//             pid: 99,
//             title: "TaskSideBars",
//             navLink: "/demo/TaskSideBars",
//             sort_number: 999994,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         },
//         {
//             id: 995,
//             pid: 99,
//             title: "SweetAlerts",
//             navLink: "/demo/SweetAlerts",
//             sort_number: 999994,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         },
//         {
//             id: 996,
//             pid: 99,
//             title: "Trees",
//             navLink: "/demo/Trees",
//             sort_number: 999994,
//             icon: < Icon.Circle size={
//                 12
//             }
//             />,
//             type: "item",
//             is_menu: 1,
//             level: 1
//         }
//     ]
// }]

const navigationConfig = []

export default navigationConfig
