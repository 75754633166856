import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    loading: false,
    update_time: "",
    searchParams: {
        club_id: "",
        game_ids: "-1",
        round_types: "-1,1,2",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const activePlayer = (state = initialState, action) => {
    switch (action.type) {
        case 'ACTIVE_PLAYER':
            return {
                ...state,
                ...action.payload
            }
        case 'ACTIVE_PLAYER_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default activePlayer
