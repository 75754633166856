const initialState = {
    loading: false,
    list: [],
    total: 0,
    searchParams: {
        page: 1,
        count: 30,
        keyword: "",
        refresh: false,
    }
}

const botList = (state = initialState, action) => {
    switch (action.type) {
        case 'BOT_LIST':
            return {
                ...state,
                ...action.payload
            }
        case 'BOT_LIST_INIT':
            return initialState
        default:
            return {...state};
    }
}

export default botList
