import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    loading: false,
    searchParams: {
        page: 1,
        count: 100,
        role_id: "",
        keyword: "",
        start_date: new Date(),
        end_date: new Date(),
        refresh: false,
        ...getUserConfig("loginLog")
    }
}

const loginLog = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_LOG':
            return {
                ...state,
                ...action.payload
            }
        case 'LOGIN_LOG_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("loginLog")
                }
            }
        default:
            return {...state};
    }
}

export default loginLog
