export const openType = {
    create: 1,
    edit: 2,
    detail: 3
}

export const appVersion = [
    {label: "全部", value: "0"},
    {label: "Main", value: "1"},
    {label: "Online Slotmachine", value: "2"},
    {label: "Vegas Slotmachine", value: "3"},
    {label: "Online Casino", value: "5"},
    {label: "Australia", value: "6"},
    {label: "Vegas Casino", value: "4"},
]

export const errorStr = {
    round: "游戏局数",
    card: "房卡",
    pay_type: "支付方式",
    pay_ratio: "房费",
    pay_mode: "支付模式",
    player_num: "人数",
    player_num_auto_start: "满人自动开始",
    bystander_num: "旁观人数",
    tie_set: "打和设置",
    buying_limit: "买活限制",
    decor_size: "花色大小",
    card_type: "牌型或翻倍规则",
    zimo_factor: "自摸倍数",
    way_of_playing: "玩法",
    tile_number: "张数",
    color_gold: "彩金",
    bonus_maximum: "彩金独大",
    lian_zhuang: "连庄",
    bet_min: "底分",
    licensing_num: "发牌数量",
    table_num: "台数",
    table_num_cap: "台数封顶",
    change_card_num: "换牌",
    join_bet_multiple: "入场条件",
    bet_add_max: "加注上限",
    ready_condition: "准备条件",
    bet_multiple: "下注倍数",
    is_noRob_nobigBet: "未抢庄不可下大注",
    hide_front_cards: "盲注",
    be_dealer_rule: "定庄规则",
    bet_push: "推注",
    countdown_oper: "操作时间/出牌时间",
    countdown_add_oper: "决策时间",
    countdown_prep: "准备时间",
    show_card_time: "看牌时间/组牌时间",
    offline_hosting: "超时托管",
    offline_hosting_second: "超时托管",
    auto_leave: "自动离开",
    suit_card: "花牌",
    wan_card: "万子",
    tong_card: "筒子",
    tiao_card: "条子",
    priority_card: "优先出牌",
    factor_cap: "倍数封顶",
    one_score: "单张分数",
    datou_share: "大头分",
    scoring_method: "计分方式",
    specialty_double: "特殊翻倍",
    scoring_count: "结算方式",
}

export const errorCode = {
    '-999999999999': '站位',
    "4001": '参数错误', // 参数错误params_error
    "4002": '未经认证的', // 未经认证的unauthenticated
    "4003": '帐户或密码错误', // 帐户或密码错误account_or_password_wrong
    "4004": '帐户已停用', // account_was_banned
    "4005": '验证码错误', // 谷歌验证码错误googleCode_error
    "4006": '权限不足', // 权限不足Insufficient_Permissions
    "-3": '服务器错误', // 服务器错误failure
    "4007": "至少选择一个权限", // Select at least one permission
    "4008": '帐户已存在', // 帐户存在account_exist
    "4009": '俱乐部CID已存在', // ClubID_already_exists
    "4010": '没有足够的房卡', // Not enough room cards
    "4011": '有关联用户', // have_relation_user
    "4012": '无法删除', // can_not_delete
    "4013": '无法更新', // can_not_update
    "4014": '帐户组名不能重复', // name_exist
    "4015": '路由错误', // uri_error
    "4016": '登录已失效,请重新登录', // re_entry
    "4017": '用户UID已存在', // user_ID_already_exists
    "4018": '原密码错误', // password_wrong
    "4019": '俱乐部名称已存在', // clubName_already_exists
    "4020": '图片宽度或高度超过限制', // image_width_or_height_exceeds_the_limit
    "4021": '没有足够的积分', // Not enough points
    "4022": '发送时间不能早于当前时间', // The send time must be a date after now
    "4023": '过期时间不能早于发送时间', // The sending time must be less than the expiration time
    "4024": '此类型公告已存在', // This type of notice already exist
    "4025": '不能对正在游戏的玩家执行操作减少', // Can not reduce the number of players who are playing
    "4026": '非法id', // There is an illegal id
    "4027": '用户不存在', // No User
    "4028": '房间不存在', // No Room
    "4029": '房间不属于当前游戏', // Room is not for the game
    "4030": '只能設置會長/代理為機器人的直屬上級', // Setup superior error
    "4031": '上级余额不足', // Insufficient balance at higher levels
    "4033": '机器人数量过多', // Excessive addition of robots
    "4034": '玩家名称重复', // Player Name Exist
    "4035": '不能大于上级奖励比例', // Cannot be greater than the percentage of superior incentives
    "4036": '不能小于直属下级奖励比例', // Cannot be less than the immediate subordinate incentive percentage
    "4037": '不能大于上级占成比例', // Cannot be greater than the percentage share of the superior
    "4038": '不能小于直属下级占成比例', // Cannot be less than the percentage of immediate subordinates
    "4039": '存在下级无法修改职位', // Existence of subordinates unable to modify positions
    "4040": '代理层级受限', // Restricted agent level
    "4041": '管理员人数已满', // Administrator quota is full
    "4042": '部分游戏被限制使用', // Some games are restricted
    "4043": '因部分游戏不存在，无法修改游戏权限部分', // Your account information has been modified. Please log in again
    "4044": '玩家创建的房间正在运行，不允许修改职位', // The room created by the player is running, and modification of positions is not allowed
    "4045": '金币余额不足', // Insufficient gold balance
    "4046": '房卡余额不足', // Insufficient room card balance
    "4047": '时间与其他计划重叠', // Time overlap with other programs
    "4048": '数据无变化', // value not change
    "4049": '无法编辑已确认的邮件', // confirmed
    "4050": '请勿重复发送', // had send before
    "4051": '规则已存在', // rule already exists
    "4052": '不能包含不恰当的词语', // Can not include improper words
    "4053": '状态已变更，请刷新页面', // Status has changed please refresh the page
    "4054": '每个游戏最少保留一个模板', // Keep at least 1 template per game
    "4055": '不能删除正在启用的模板', // Cannot delete active templates
    "4056": '服务器正在运行，不能编辑启用的模板', // Cannot delete active templates
    "4057": '服务器正在运行，不能切换启用的模板', // Cannot delete active templates
    "4058": '同一款游戏必须启用一个模板', // A template must be enabled
    "4059": '不能编辑相同账户组的账号', // Cannot edit accounts in the same account group
    "4060": '不能创建相同账户组的账号', // You cannot create accounts with the same account group
    // "4061": 'ClubCs数量已达上限', // You cannot bind a club which has bind ClubOwner
    "4062": '该俱乐部的所有成员都无法持有房卡', // Club member can not has card
    "4063": '该俱乐部已关联了其他ClubOwner', // bind_num_over_limit_club_owner
    "4064": '该ClubOwner无法绑定更多的ClubCs', // bind_num_over_limit_club_owner
    "4065": '该ClubOwner无法绑定更多的ClubAgent', // bind_num_over_limit_club_owner
    "4066": '必须是会长的直属会员才可以成为管理', // editor_must_be_owner_child
    "4067": '存在已使用的昵称或ID', // editor_must_be_owner_child
    "4068": '用户UID已被使用', // editor_must_be_owner_child
    "4069": '用户昵称已被使用', // editor_must_be_owner_child
    '200': '成功',
    '201': '登录成功',
    '202': '退出成功',
    '401': 'token未设置',
    '402': '无权限访问',
    '403': '验证失败',
    '466': 'Authenticator Code验证失败',
    '999999999999': '站位'
}
