import React, {useEffect, memo} from "react"
import {Badge, DropdownMenu, DropdownToggle, Media, Spinner, UncontrolledDropdown} from "reactstrap";
import {Bell} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import {getCardWarnNum} from "@redux/actions/navbar";
import {useDispatch, useSelector} from "react-redux";
import {decodeUserMess, saveUrlParams} from "@utility/utils";
import {history} from "@history";
import "./index.scss"

// let isOpen = false
let timer = null

const NumBadge = ({color, num, className}) =>
    <Badge pill color={color}
           className={className}
           style={{
               padding: "0.1rem 0.1rem"
           }}
    >
        {Math.min(num, 99)}{num > 99 ? "+" : ""}
    </Badge>

const NavbarBell = () => {
    const dispatch = useDispatch()
    const {navbar} = useSelector(state => state.navbar)
    const {cardWarn, feedback, loading} = navbar
    const {is_pwd} = decodeUserMess()

    const total = cardWarn.value + feedback.unread + feedback.critical

    // 获取需要得到的通知
    const getNotice = async (params) => {
        if (is_pwd === 0) {
            await dispatch(getCardWarnNum({update: true, ...params}))
        }
    }

    // 距离现在时间多久
    /* function dateDiff(hisTime, nowTime) {
        if (!arguments.length) return '';

        let arg = arguments,
            now = arg[1] ? arg[1] : new Date().getTime(),
            diffValue = now - new Date(arg[0].replace(/-/g, '/')).getTime(),
            result,
            minute = 1000 * 60,
            hour = minute * 60,
            day = hour * 24,
            month = day * 30,
            year = month * 12,

            _year = diffValue / year,
            _month = diffValue / month,
            _week = diffValue / (7 * day),
            _day = diffValue / day,
            _hour = diffValue / hour,
            _min = diffValue / minute;
        // if (new Date().toDateString() == convertDateFromString(hisTime).toDateString()) {
        //     result.isToday = true;
        // }
        if (_year >= 1) result = global.t('yearsAgo', {text: "{{count}} years ago", count: parseInt(`${_year}`)})
        else if (_month >= 1) result = global.t('monthsAgo', {
            text: "{{count}} months ago",
            count: parseInt(`${_month}`)
        })
        else if (_week >= 1) result = global.t('weeksAgo', {text: "{{count}} weeks ago", count: parseInt(`${_week}`)})
        else if (_day >= 1) result = global.t('daysAgo', {text: "{{count}} days ago", count: parseInt(`${_day}`)})
        else if (_hour >= 1) result = global.t('hoursAgo', {text: "{{count}} hours ago", count: parseInt(`${_hour}`)})
        else if (_min >= 1) result = global.t('minutesAgo', {text: "{{count}} minutes ago", count: parseInt(`${_min}`)})
        else result = "刚刚";
        return result;
    }*/

    const doInterval = (func) => {
        cancelInterval()
        if (func) {
            func()
            timer = setTimeout(() => {
                doInterval(func);
            }, 3600000);
        }
    }

    const cancelInterval = () => {
        clearTimeout(timer)
        timer = null
    }

    useEffect(() => {
        getNotice({update: false})
        doInterval(getNotice)
        let hiddenProperty = 'hidden' in document ? 'hidden' :
            'webkitHidden' in document ? 'webkitHidden' :
                'mozHidden' in document ? 'mozHidden' :
                    null;
        let visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
        let onVisibilityChange = function () {
            if (!document[hiddenProperty]) {
                getNotice({update: false})
                doInterval(getNotice)
            } else {
                // console.log(`隐藏${new Date()}`)
                cancelInterval()
            }
        }
        document.addEventListener(visibilityChangeEvent, onVisibilityChange);

        return () => {
            document.removeEventListener(visibilityChangeEvent, onVisibilityChange)
        }
    }, [])

    // const getList = () => {
    // if (isOpen) dispatch(getNoticeDCoinsRecord({
    //     recordID: "",
    //     rID: "",
    //     increase_decrease: "",
    //     page: 1,
    //     count: 30 * currentPage,
    //     status: 0,
    // }))
    // }

    /* const onYReachEnd = debounce((e) => {
        if (e.scrollTop !== 0) {
            currentPage += 1
            if (total >= (currentPage - 1) * 10) getList()
        }
    }, 500, true)*/

    return <UncontrolledDropdown
        tag="li"
        id="noticeDropdown"
        className="d-flex align-items-center nav-item navbar-custom"
        // onToggle={(_, toggled) => {
        // isOpen = toggled
        // getList()
        // }}
    >
        <DropdownToggle tag="a" className={`nav-link nav-link-label ${total !== 0 ? "shake-element" : ""}`}>
            <Bell size={21}/>
            {
                total !== 0 && <NumBadge color="danger badge-up"
                                         num={total}/>
            }
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
                <div className="d-flex justify-content-between align-items-center mt-0">
                    <h3>{global.t("通知")}</h3>
                    {total !== 0 && <Badge pill color="light-danger">{total} {global.t("未处理")}</Badge>}
                </div>
            </li>
            <PerfectScrollbar
                className="media-list overflow-auto position-relative ps maxHeight-300"
                options={{
                    wheelPropagation: false
                }}
                // onYReachEnd={onYReachEnd}
            >
                {
                    loading ? <Spinner size='sm' color='secondary'/> :
                        Object.keys(navbar).map((item) => {
                            if (item !== "loading" && (navbar[item].value || navbar[item].critical || navbar[item].unread)) {
                                return <div className="d-flex justify-content-between"
                                            key={item}
                                            onClick={() => {
                                                if (item === "cardWarn" && window.location.pathname !== "/club/CardWarn") {
                                                    saveUrlParams(item, true)
                                                }
                                                history.push(navbar[item].href)
                                            }}>
                                    <Media className="d-flex align-items-start">
                                        {/* <Media left href="#">
                                    <Feather
                                        className="font-medium-5 primary"
                                        size={18}
                                    />
                                </Media> */}
                                        <Media body className="d-flex align-items-center justify-content-between">
                                            <Media heading className="primary media-heading mb-0" tag="h6">
                                                {global.t(navbar[item].label)}
                                            </Media>
                                            {
                                                item !== "feedback" ?
                                                    <NumBadge color="danger"
                                                              num={navbar[item].value}/> :
                                                    <div>
                                                        <NumBadge color="danger"
                                                                  className="mR2"
                                                                  num={navbar[item].critical}/>
                                                        <NumBadge color="success"
                                                                  num={navbar[item].unread}/>
                                                    </div>
                                            }
                                            {/* <p className="notification-text">
                                        {toFormatNum(navbar[item].value, {format: true}, true)}
                                    </p>*/}
                                        </Media>
                                    </Media>
                                </div>
                            }
                            return ""
                        })
                }
            </PerfectScrollbar>
            {/* <li className="dropdown-menu-footer">
                <DropdownItem tag="a" className="p-1 text-center" onClick={() => {
                    history.push("/resellerconsole/DRequest")
                }}>
                    <span className="align-middle">{global.t("打开页面")}</span>
                </DropdownItem>
            </li>*/}
        </DropdownMenu>
    </UncontrolledDropdown>
}

export default memo(NavbarBell, () => true)
