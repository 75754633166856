import {getUserConfig, getWeekDay} from "@utility/utils";

const data = getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        clubNo: "",
        admin_info: "",
        editType: "",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubEditRecord")
    }
}

const editRecord = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_EDIT_RECORD':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_EDIT_RECORD_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubEditRecord")
                },
            }
        default:
            return {...state};
    }
}

export default editRecord
