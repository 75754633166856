import {getUserConfig, getWeekDay} from "@utility/utils";

const data = getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    searchParams: {
        clubNo: "",
        user_key_word: "",
        gameIDs: "-1",
        event_types: "-1,3040000,3050000,3010000,3020000,2040000,2050200,9060000,3040100,3040200,5020100,5020200",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("roomCard")
    },
    detail: {},
}

const roomCard = (state = initialState, action) => {
    switch (action.type) {
        case 'ROOM_CARD':
            return {
                ...state,
                ...action.payload
            }
        case 'ROOM_CARD_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("roomCard")
                },
            }
        case 'ROOM_CARD_DETAIL':
            return {
                ...state,
                detail: action.payload,
            }
        case 'ROOM_CARD_DETAIL_INIT':
            return {
                ...state,
                detail: {},
            }
        default:
            return {...state};
    }
}

export default roomCard
