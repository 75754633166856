import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    total: 0,
    loading: false,
    submitting: false,
    pending: false,
    searchParams: {
        refresh: false,
        target_type: "",
        status: "",
        operatorID: "",
        start_date: "",
        end_date: "",
        page: 1,
        count: 30,
        ...getUserConfig("emailData")
    },
    accessory: {
        loading: false,
        list: [],
        total: 0
    },
    receive: {
        loading: false,
        searchParams: {
            refresh: false,
            keyword: "",
            page: 1,
            count: 30,
        },
        list: [],
        total: 0
    }
}

const email = (state = initialState, action) => {
    switch (action.type) {
        case "EMAIL_DATA":
            return {
                ...state,
                ...action.payload
            }
        case "EMAIL_DATA_RELOAD":
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    refresh: true
                }
            }
        case 'EMAIL_DATA_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams,
                    ...getUserConfig("emailData")
                }
            }
        case 'MAIL_ACCESSORY':
            return {
                ...state,
                accessory: {
                    ...state.accessory,
                    ...action.payload
                },
            }
        case 'MAIL_RECEIVE':
            return {
                ...state,
                receive: {
                    ...state.receive,
                    ...action.payload
                },
            }
        case 'MAIL_RECEIVE_INIT':
            return {
                ...state,
                receive: initialState.receive
            }
        default:
            return {...state};
    }
}

export default email
