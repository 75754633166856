import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    players: {},
    total: 0,
    loading: false,
    pending: false,
    searchParams: {
        clubID: "",
        userIDs: "",
        tableID: "",
        tableNum: "",
        rID: "",
        start_date: "",
        end_date: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("uniquePlay")
    }
}

const uniquePlay = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_DESK_TABLE':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_DESK_TABLE_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("uniquePlay")
                },
            }
        default:
            return {...state};
    }
}

export default uniquePlay
