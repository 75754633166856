import {getSevDay} from "@utility/utils";

const data = getSevDay(0, 1)

const initialState = {
    list: [],
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        app_type: "",
        channel_type: "-1,0,1,2,3,4,5",
        start_date: data[0],
        end_date: data[1],
        // start_date: moment().subtract(parseInt(moment().format('E')) - 1, 'days').format('YYYY-MM-DD'),
        // end_date: moment().subtract(parseInt(moment().format('E')) - 7, 'days').format('YYYY-MM-DD'),
        refresh: false,
    }
}

const signRecord = (state = initialState, action) => {
    switch (action.type) {
        case 'RETAIN_RECORD':
            return {
                ...state,
                ...action.payload
            }
        case 'RETAIN_RECORD_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default signRecord
