import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    count: 0,
    total: 0,
    loading: false,
    submitting: false,
    searchParams: {
        club_id: "",
        owner_info: "",
        day_num: "",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("cardWarn")
    }
}

const cardWarn = (state = initialState, action) => {
    switch (action.type) {
        case 'CARD_WARN':
            return {
                ...state,
                ...action.payload
            }
        case 'CARD_WARN_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("cardWarn")
                },
            }
        default:
            return {...state};
    }
}

export default cardWarn
