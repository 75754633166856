import {getSevDay} from "@utility/utils";

const data = getSevDay(0)

const initialState = {
    list: [],
    summary: {},
    dataList: [],
    loading: false,
    update_time: "",
    searchParams: {
        app_type: "",
        start_date: data[0],
        end_date: data[1],
        refresh: false,
    }
}

const framework = (state = initialState, action) => {
    switch (action.type) {
        case 'NEW_FRAMEWORK':
            return {
                ...state,
                ...action.payload
            }
        case 'NEW_FRAMEWORK_INIT':
            return {
                ...initialState
            }
        default:
            return {...state};
    }
}

export default framework
