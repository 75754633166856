import {combineReducers} from "redux"
import cardDeal from "./deal";
import autoClub from "./autoClub";

const testTool = combineReducers({
    cardDeal,
    autoClub,
})

export default testTool
