const initialState = {
    data: {},
    loading: false,
    submitting: false,
}

const mtsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TIME_SETTING':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

export default mtsReducer
