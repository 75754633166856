import {getUserConfig, getWeekDay} from "@utility/utils";

const data =getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    loading: false,
    searchParams: {
        clubNo: "",
        operatorName: "",
        cType: "-1,1,2,3",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubPointCredit")
    }
}

const pointCredit = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_POINT':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_POINT_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubPointCredit")
                },
            }
        default:
            return {...state};
    }
}

export default pointCredit
