import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    count: 0,
    total: 0,
    loading: false,
    pending: false,
    searchParams: {
        clubID: "",
        userIDa: "",
        userIDb: "",
        agentIDa: "",
        agentIDb: "",
        gameID: 1006,
        type: "1",
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("deskRate")
    }
}

const deskRate = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_DESK_RATE':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_DESK_RATE_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("deskRate")
                },
            }
        default:
            return {...state};
    }
}

export default deskRate
