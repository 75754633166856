import React from "react"
import PropTypes from 'prop-types';
import {Breadcrumb, BreadcrumbItem} from "reactstrap"
import {Link} from "react-router-dom"
import './index.scss'

// 面包屑
class Breadcrumbs extends React.Component {

    renderHeader = () => {
        let {headerData} = this.props;
        headerData = headerData || [];
        return headerData.map((item, index) => {
            if (index === headerData.length - 1 || index === 0) {
                return <BreadcrumbItem key={item.key}>{global.t(item.name)}</BreadcrumbItem>
            } else {
                return <BreadcrumbItem key={item.key}><Link to={item.path}
                                                            key={item.key}>{global.t(item.name)}</Link></BreadcrumbItem>
            }
        });
    }

    render() {
        let {headerData = []} = this.props;
        return (
            <Breadcrumb className='breadcrumb-slash breadShow'>
                {(headerData.length > 0 && headerData[0].key !== 'newUser') &&
                <BreadcrumbItem key={'home'}><Link to={'/pages/home'}
                                                   key={'homePage'}>{global.t("首页")}</Link></BreadcrumbItem>}
                {this.renderHeader()}
            </Breadcrumb>
        )
    }
}

Breadcrumbs.propTypes = {
    headerData: PropTypes.array
}

export default Breadcrumbs
