import {customFixed} from "@utility/utils";

export const handleRule = (score) => {
    if (Number(score) >= 10000) {
        return global.t("scoreM", {
            text: "{{count}}万分",
            count: customFixed(Number(score) / 10000)
        })
    }
    return customFixed(score)
}

// 结算ID转进制
export function encodeSettle(t, roomType) {
    // 根据房间类型生成前缀：1-大厅；2-好友，3-俱乐部
    const obj = {
        1: "a",
        2: "a",
        3: "b"
    }
    return t ? parseInt(`${obj[roomType]}${t}`, 16).toString(10) : "";
}

// 获取原结算ID
export function decodeSettle(str) {
    // 转换进制，移除前缀，获取原数字
    return str ? parseInt(`${str}`, 10).toString(16) : "";
}

export const isMj = (game_id) => [1006, 1007, 1010].includes(game_id)
