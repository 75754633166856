import {getUserConfig} from "@utility/utils";

const initialState = {
    list: [],
    player: {
        playerList: [],
        pending: false
    },
    total: 0,
    loading: false,
    searching: false,
    searchParams: {
        userID: "",
        roomNo: "",
        roomID: "",
        settleID: "",
        matchNo: "",
        roomType: "1",
        status: "4",
        gameIDs: "-1",
        start_date: undefined,
        end_date: undefined,
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("roomPub")
    },
}

const roomPub = (state = initialState, action) => {
    switch (action.type) {
        case 'ROOM_PUB_LIST':
            return {
                ...state,
                ...action.payload
            }
        case 'ROOM_PUB_PLAYER_LIST':
            return {
                ...state,
                player: {
                    ...state.player,
                    ...action.payload
                }
            }
        case 'ROOM_PUB_LIST_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("roomPub")
                },
            }
        default:
            return {...state};
    }
}

export default roomPub
