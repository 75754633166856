import {getUserConfig, getWeekDay} from "@utility/utils";

const data = getWeekDay(0)

const initialState = {
    list: [],
    total: 0,
    loading: false,
    update_time: "",
    searchParams: {
        clubNo: "",
        club_name: "",
        avatar_url: "",
        userID: "",
        sortedType: 1,
        sorted: "desc",
        start_date: data[0],
        end_date: data[1],
        page: 1,
        count: 30,
        refresh: false,
        ...getUserConfig("clubRanking")
    }
}

const ranking = (state = initialState, action) => {
    switch (action.type) {
        case 'CLUB_RANKING':
            return {
                ...state,
                ...action.payload
            }
        case 'CLUB_RANKING_INIT':
            return {
                ...initialState,
                searchParams: {
                    ...initialState.searchParams, ...getUserConfig("clubRanking")
                },
            }
        default:
            return {...state};
    }
}

export default ranking
