import {combineReducers} from "redux"
import hallCredit from "./hallCredit";
import config from "./config";

const playerReducer = combineReducers({
    hallCredit,
    config,
})

export default playerReducer
