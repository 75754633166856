const initialState = {
    list: [],
    total: 0,
    userInfo: {},
    submitting: false,
    checking: false,
    loading: false,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_DATA':
        case 'USEREDIT_DATA':
        case 'USERINFO_DATA':
            return {
                ...state,
                ...action.payload
            }
        default:
            return {...state};
    }
}

// export const usereditReducer = (state = {}, action) => {
//     switch (action.type) {
//         case 'USEREDIT_DATA':
//             return {
//                 ...state,
//                 ...action.payload
//             }
//         default:
//             return { ...state };
//     }
// }

export default userReducer
