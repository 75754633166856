import {combineReducers} from "redux"
import botList from "./botList"
import botSetting from "./botSetting"

const botReducer = combineReducers({
    botList,
    botSetting,
})

export default botReducer
