import {init} from 'i18next'
import translation_tw from "../locales/tw.json";
import {getLocale} from "@utility/utils";

export const langArrNew = {
    tw: {
        title: '繁體中文',
        countryCode: 'cn'
    },
};

const resources = {
    tw: {
        key: 'cn',
        title: '繁體中文',
        countryCode: 'cn',
        translation: translation_tw
    },
}

init({
    lng: getLocale(),
    debug: false,
    resources
});
